import { useRef } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';

// Actions
import { DatafileActions } from '../../../store/slices/datafile/datafileSlice';

// Constants
import { COLUMN_FILTER_TYPES } from '../../../constants/datasetConstants';

// Css
import './ColumnSearch.scss';

export default function ColumnSearch({ datafileId, searchProps, dataIndex }) {
  const searchInput = useRef(null);

  const dispatch = useDispatch();

  const handleSearch = (selectedKeys, setSelectedKeys, closeSearch) => {
    closeSearch();
    setSelectedKeys([]);
    dispatch(
      DatafileActions.addFilter({
        datafileId,
        column: dataIndex,
        filterTag: selectedKeys[0],
        tagType: COLUMN_FILTER_TYPES.SEARCH,
      }),
    );
  };

  const { setSelectedKeys, selectedKeys, confirm: closeSearch } = searchProps;
  return (
    <div className="search-wrapper" onKeyDown={(e) => e.stopPropagation()}>
      <div className="search-input">
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onPressEnter={() =>
            handleSearch(selectedKeys, setSelectedKeys, closeSearch)
          }
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
        />
      </div>
    </div>
  );
}
