import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Form, Input, Modal, Space } from 'antd';

// Components
import Button from '../../../components/button/Button';

// Actions
import { DatasetActions } from '../../../store/slices/dataset/datasetSlice';

// Css
import './DatasetCreateEditModal.scss';

// Form Action Function
function FormActions({
  handleReset = () => {},
  handleSubmit = () => {},
  btnLoading = false,
}) {
  return (
    <Form.Item className="d-flex flex-row justify-content-end align-items-center">
      <Space>
        <Button
          className="btn-outline-dark btn-form-reset"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          className="btn-primary"
          onClick={handleSubmit}
          loading={btnLoading}
        >
          Submit
        </Button>
      </Space>
    </Form.Item>
  );
}

/**
 * Dataset Create / Edit Modal
 * @param {x} openModal : boolean
 * @param {x} setOpenModal : function
 * @param {x} editDatasetData : boolean
 * @param {x} setEditDatasetData : function
 * @returns
 */
export default function DatasetCreateEditModal({
  openModal = false,
  setOpenModal = () => {},
  editDatasetData = {},
  setEditDatasetData = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Form
  const [form] = Form.useForm();

  // States
  const [validationError, setValidationError] = useState('');
  const [showError, setShowError] = useState(false);

  const { key: datasetId = '', name = '', description = '' } = editDatasetData;

  // Selectors
  const errorFromApi = useSelector((state) => state.dataset.upsertDatasetError);
  const upsertDatasetSuccess = useSelector(
    (state) => state.dataset.upsertDatasetSuccess,
  );
  const upsertDatasetLoading = useSelector(
    (state) => state.dataset.upsertDatasetLoading,
  );

  const modalTitle = datasetId ? 'Edit Dataset' : 'Create New Dataset';
  const open = upsertDatasetLoading ? true : openModal;

  function handleUpsert(values = {}) {
    setValidationError('');
    setShowError(false);
    if (datasetId) {
      dispatch(
        DatasetActions.updateDataset({
          datasetId,
          datasetObj: values,
        }),
      );
      return;
    }

    dispatch(DatasetActions.createDataset({ datasetObj: values }));
  }

  // Function to handle the form submission
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        handleUpsert(values);
      })
      .catch((info) => {
        setValidationError(info.errorFields[0].errors[0]);
        setShowError(true);
      });
  };

  // Function to handle the form reset
  const handleReset = () => {
    setValidationError('');
    setShowError(false);
    form.setFieldsValue({ name, description });
  };

  // Function to handle modal cancel
  function handleCancel() {
    setOpenModal(false);
    setEditDatasetData({});
    setValidationError('');
    setShowError(false);
  }

  useEffect(() => {
    if (upsertDatasetSuccess) {
      setOpenModal(false);
    }

    if (Object.keys(errorFromApi).length !== 0) {
      setValidationError('Error creating dataset');
      setShowError(true);
    }
  }, [upsertDatasetSuccess, JSON.stringify(errorFromApi)]);

  useEffect(() => {
    form.setFieldsValue({ name, description });
  }, [datasetId]);

  return (
    <Modal
      open={open}
      title={modalTitle}
      onCancel={handleCancel}
      width={600}
      footer={false}
      afterClose={handleReset}
      forceRender
    >
      {/** Error Alert */}
      {showError && <Alert message={validationError} type="error" />}

      <Form layout="vertical" className="my-4" form={form}>
        <Form.Item
          label="Name :"
          name="name"
          className="fw-semibold"
          rules={[
            {
              required: true,
              message: 'Please enter Name!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Description :"
          name="description"
          className="fw-semibold"
        >
          <Input.TextArea rows={4} />
        </Form.Item>
        <FormActions
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          btnLoading={upsertDatasetLoading}
        />
      </Form>
    </Modal>
  );
}
