// Constants
import PageUrls from '../../constants/pageUrls';

// Components
import ErrorHandler from '../../components/hoc/ErrorHandler';
import ProtectedRoute from '../../components/hoc/ProtectedRoute';

// Layouts
import DatafileLayout from './DatafileLayout';

// Pages
import DatafileViewPage from '../../pages/datafile/DatafileViewPage';

// Main Pages and Layouts
export const DatafileRoutes = {
  path: PageUrls.DatafileViewPage,
  element: (
    // ErrorHandler
    <ErrorHandler>
      {/* ProtectedRoute */}
      <ProtectedRoute>
        {/* Layout */}
        <DatafileLayout />
      </ProtectedRoute>
    </ErrorHandler>
  ),
  children: [
    {
      path: PageUrls.DatafileViewPage,
      element: <DatafileViewPage />,
    },
  ],
};
