// App Client
import HttpClientFactory from '../../utils/httpClientFactory';

//Urls
import { DatafileUrls } from '../../constants/serviceUrls';

// Constants
import { HttpContentTypes } from '../../constants/httpConstants';

// Utils
import UrlUtils from '../../utils/urlUtils';

/**
 * Fetch the list of datafiles associated with the dataset
 * @param {*} datasetId
 * @param {*} pageNumber
 * @param {*} pageSize
 * @returns
 */
async function listDatafiles(datasetId, pageNumber, pageSize) {
  const params = {
    pn: pageNumber,
    ps: pageSize,
  };
  const url = UrlUtils.format(DatafileUrls.listDatafiles, { datasetId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}

/**
 * Fetch the DataFile Info
 * @param {*} datasetId
 * @param {*} datafileId
 * @returns
 */
async function getDatafileInfo(datasetId, datafileId) {
  const url = UrlUtils.format(DatafileUrls.getDatafileInfo, {
    datasetId,
    datafileId,
  });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url);
}

/**
 * Fetch the DataFile data
 * @param {*} datasetId
 * @param {*} datafileId
 * @returns
 */
async function getDatafile(datasetId, datafileId) {
  const url = UrlUtils.format(DatafileUrls.getDatafile, { datasetId });
  const params = {
    fileIdCSV: datafileId,
  };

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}

/**
 * Upload DataFile
 * @param {*} datasetId
 * @param {*} datafileObj
 * @returns
 */
async function uploadDatafile(datasetId, datafileObj) {
  const formData = new FormData();
  formData.append('file', datafileObj);

  const url = UrlUtils.format(DatafileUrls.uploadDatafile, { datasetId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(
    url,
    {},
    formData,
    HttpContentTypes.MULTIPART_FORM_DATA,
  );
}

const DatafileService = {
  listDatafiles,
  getDatafile,
  getDatafileInfo,
  uploadDatafile,
};

export default DatafileService;
