import { createSlice } from '@reduxjs/toolkit';

// Utils
import DataTableUtils from '../../../utils/dataTableUtils';
import DatafileFilterUtils from '../../../utils/datafileFilterUtils';

// Transformers
import {
  getTransformedDatafile,
  getTransformedDatafileInfo,
  getTransformedDatafilesList,
} from './datafileTransformer';

// constants
import { COLUMN_FILTER_TYPES } from '../../../constants/datasetConstants';

// Table style config
const tableConfig = { columnWidth: 100 };

// initial state
const initialState = {
  // list datafiles state
  datafiles: {},
  listDatafilesLoading: false,
  listDatafilesError: {},

  // view Datafile state
  datafileMap: {},
  datafileLoading: false,
  viewDatafileError: {},

  // Datafile basic info state
  datafileName: '',
  getDatafileInfoLoading: false,
  getDatafileInfoError: {},

  //upload dataFile state
  uploadDatafileLoading: false,
  uploadDatafileSuccess: false,
  uploadDatafileError: {},

  // data search, filter config
  filteredDataMap: {},
  isFiltersApplied: false,
  dataFilterConfig: {},
};

const datafileSlice = createSlice({
  name: 'Datafiles',
  initialState,
  reducers: {
    // List DataFile Actions
    listDatafiles: (state) => {
      state.listDatafilesError = {};
      state.listDatafilesLoading = true;
    },

    listDatafilesSuccess: (state, action) => {
      state.listDatafilesLoading = false;

      const { datasetId, datafilesObj } = action.payload;
      const datafiles = getTransformedDatafilesList(datafilesObj);
      const { columns, rows } = DataTableUtils.constructDatafileTableData(
        datasetId,
        datafiles,
        tableConfig,
      );
      state.datafiles = { columns, rows };
    },

    listDatafilesFailure: (state, action) => {
      state.listDatafilesLoading = false;
      state.listDatafilesError = action.payload.error;
    },

    // Get DataFile Actions
    getDatafile: (state) => {
      state.viewDatafileError = {};
      state.datafileLoading = true;
    },

    getDatafileSuccess: (state, action) => {
      const { datafileId, columnConfig, datafileObj } = action.payload;
      state.datafileLoading = false;

      const { headers, data } = getTransformedDatafile(datafileObj);
      const { columns, rows } = DataTableUtils.constructTableData(
        datafileId,
        headers,
        columnConfig,
        data,
        tableConfig,
      );
      state.datafileMap[datafileId] = { columns, rows };
    },

    getDatafileFailure: (state, action) => {
      const { error } = action.payload;
      state.datafileLoading = false;
      state.viewDatafileError = error;
    },

    // Upload DataFile Actions
    uploadDatafile: (state) => {
      state.uploadDatafileLoading = true;
      state.uploadDatafileSuccess = false;
      state.uploadDatafileError = '';
    },

    uploadDatafileSuccess: (state) => {
      state.uploadDatafileLoading = false;
      state.uploadDatafileSuccess = true;
    },

    uploadDatafileFailure: (state, action) => {
      state.uploadDatafileLoading = false;
      state.uploadDatafileSuccess = false;
      state.uploadDatafileError = action.payload.error;
    },

    addFilter: (state, action) => {
      const { datafileId, column, filterTag, tagType } = action.payload;
      const filterTagMap = state.dataFilterConfig[datafileId] || {};

      if (tagType === COLUMN_FILTER_TYPES.SEARCH) {
        filterTagMap[column] = {
          tagType: COLUMN_FILTER_TYPES.SEARCH,
          filterTag,
        };
      }

      state.dataFilterConfig[datafileId] = filterTagMap;

      // Filter the data..
      const datafile = state.datafileMap[datafileId];
      const tagConfig = state.dataFilterConfig[datafileId];
      state.filteredDataMap[datafileId] = DatafileFilterUtils.filter(
        datafile,
        tagConfig,
      );

      state.isFiltersApplied = true;
    },

    removeFilter: (state, action) => {
      const { datafileId, column, tagType } = action.payload;
      const filterTagMap = state.dataFilterConfig[datafileId] || {};

      if (tagType === COLUMN_FILTER_TYPES.SEARCH) {
        delete filterTagMap[column];
      }

      state.dataFilterConfig[datafileId] = filterTagMap;

      // Filter the data..
      state.filteredDataMap[datafileId] = DatafileFilterUtils.filter(
        state.datafileMap[datafileId],
        state.dataFilterConfig[datafileId],
      );
    },

    getDatafileInfo: (state) => {
      state.getDatafileInfoLoading = true;
      state.getDatafileInfoError = {};
    },

    getDatafileInfoSuccess: (state, action) => {
      state.getDatafileInfoLoading = false;
      const { datafileInfo } = action.payload;
      const { datafileName } = getTransformedDatafileInfo(datafileInfo);
      state.datafileName = datafileName;
    },

    getDatafileInfoFailure: (state, action) => {
      state.getDatafileInfoLoading = false;
      state.getDatafileInfoError = action.payload.error;
    },

    //---- clear Upload Details -----//
    reset: () => initialState,
  },
});

// Reducer
export const DatafileReducer = datafileSlice.reducer;

// Actions
export const DatafileActions = datafileSlice.actions;
