import { useEffect, useState } from 'react';
import { Alert, Modal, Upload } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Upload as UploadIcon } from 'lucide-react';
import { useParams } from 'react-router-dom';

// Actions
import { DatafileActions } from '../../../store/slices/datafile/datafileSlice';

// Constants
import { FileTypes } from '../../../constants/generalConstants';

// Components
import Button from '../../../components/button/Button';

// Images
import XLSXIcon from '../../../assets/images/icons/excel.png';

// CSS
import './DatafileUploadModal.scss';

// Modal Footer Function
function ModalFooter({
  handleReset = () => {},
  handleUpload = () => {},
  btnLoading = false,
}) {
  return (
    <div className="d-flex justify-content-end gap-3">
      <Button
        onClick={handleReset}
        className="btn-outline-dark btn-outline-custom px-3 py-2"
      >
        Reset
      </Button>
      <Button
        onClick={handleUpload}
        className="btn btn-primary"
        loading={btnLoading}
      >
        Import
      </Button>
    </div>
  );
}

// Upload Dragger Body Function
function UploadDraggerBody({ isFileSelected = false, file = {} }) {
  const { name } = file;

  return (
    <>
      {/* Display when selected file state is true */}
      {isFileSelected && (
        <>
          <img src={XLSXIcon} alt="XLSX Image" className="xlsx-icon" />
          <p className="mt-3">{name}</p>
        </>
      )}

      {/* Display when selected file state is false */}
      {!isFileSelected && (
        <>
          <UploadIcon />
          <p className="ant-upload-text fs-5 fw-semibold my-2 opacity-75">
            Drag & Drop or <span className="text-primary">Choose file</span> to
            upload
          </p>
          <p className="ant-upload-hint m-0">File Type : XLSX, CSV</p>
        </>
      )}
    </>
  );
}

/**
 * DatafileUploadModal Component
 * @param {*} openModal : Boolean value
 * @param {*} setOpenModal : Function
 */
export default function DatafileUploadModal({
  openModal = false,
  setOpenModal = () => {},
}) {
  // dispatch
  const dispatch = useDispatch();

  // Path params
  const { datasetId } = useParams();

  // States
  const [file, setFile] = useState({});
  const [isFileSelected, setIsFileSelected] = useState(false);

  const [validationError, setValidationError] = useState('');
  const [showError, setShowError] = useState(false);
  const errorFromApi =
    useSelector((state) => state.datafile.uploadDatafileError) || {};

  const uploadDatafileLoading = useSelector(
    (state) => state.datafile.uploadDatafileLoading,
  );
  const isUploadSuccess = useSelector(
    (state) => state.datafile.uploadDatafileSuccess,
  );

  useEffect(() => {
    if (isUploadSuccess) {
      setOpenModal(false);
    }

    if (Object.keys(errorFromApi).length !== 0) {
      setValidationError('Error uploading file');
      setShowError(true);
    }
  }, [isUploadSuccess, JSON.stringify(errorFromApi)]);

  // Function to check the selected file is xlsx or not
  function onBeforeUpload(file) {
    const isValidFileType =
      file.type === FileTypes.XLSX || file.type === FileTypes.CSV;

    if (!isValidFileType) {
      setValidationError('Invalid file type');
      setShowError(true);
      setIsFileSelected(false);
      return false;
    }

    setFile(file);
    setIsFileSelected(true);
    return false;
  }

  // Handle Reset Function
  const handleReset = () => {
    setFile({});
    setIsFileSelected(false);
    setValidationError('');
    setShowError(false);
  };

  // Handle Submit Function
  function handleUpload() {
    if (Object.keys(file).length === 0) {
      setValidationError('File not selected');
      setShowError(true);
      return;
    }
    setValidationError('');
    setShowError(false);
    dispatch(DatafileActions.uploadDatafile({ datasetId, datafileObj: file }));
    // setOpenModal(false);
  }

  return (
    <Modal
      open={uploadDatafileLoading ? true : openModal}
      title="Upload Data File"
      onCancel={() => setOpenModal(false)}
      width={600}
      footer={() => (
        <ModalFooter
          handleReset={handleReset}
          handleUpload={handleUpload}
          btnLoading={uploadDatafileLoading}
        />
      )}
      afterClose={handleReset}
    >
      {/** Error Alert */}
      {showError && <Alert message={validationError} type="error" />}

      <div className="my-4">
        <Upload.Dragger
          disabled={isFileSelected}
          showUploadList={false}
          beforeUpload={onBeforeUpload}
          multiple={false}
          height={200}
        >
          <UploadDraggerBody isFileSelected={isFileSelected} file={file} />
        </Upload.Dragger>
      </div>
    </Modal>
  );
}
