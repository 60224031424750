export function getTransformedDatafilesList(datafilesObj) {
  // Response from the API
  const { items } = datafilesObj || {};

  // Setting the values in the reducer
  return items;
}

export function getTransformedDatafile(datafileObj) {
  // Response from the API
  const { headers, rows } = datafileObj || {};

  // Setting the values in the reducer
  return { headers: headers, data: rows };
}

export function getTransformedDatafileInfo(datafileInfo) {
  const { fileName = '' } = datafileInfo;
  return { datafileName: fileName };
}
