import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';

// Components
import Logo from '../../components/logo/Logo';

// Images
import URL_IMG_LOGO from '../../assets/images/logo/datum.png';

// Page Components
import LoginForm from './LoginForm';

// Constants
import PageUrls from '../../constants/pageUrls';
import AuthUtils from '../../utils/authUtils';

function getLoginErr(validationErr, apiErr) {
  if (validationErr) {
    return 'Enter valid username/password';
  }

  if (apiErr) {
    return 'Invalid username/password';
  }

  return '';
}

function LoginError({ validationErr }) {
  const apiErr = useSelector((state) => state.auth.loginError);
  const loginErrMgs = getLoginErr(validationErr, apiErr);

  if (!loginErrMgs) {
    return null;
  }

  return (
    <div className="card bg-secondary-subtle">
      <div className="card-body text-center text-danger">
        <i className="fa-solid fa-circle-xmark me-2"></i>
        {loginErrMgs}
      </div>
    </div>
  );
}

/**
 * Login Page
 */
export default function LoginPage() {
  const [validationErr, setValidationErr] = useState('');

  const authUser = useSelector((state) => state.auth.authUser);

  if (Object.keys(authUser).length !== 0 || AuthUtils.isUserAuthenticated()) {
    return <Navigate to={PageUrls.DashboardPage} />;
  }

  return (
    <div className="container my-5 pt-sm-5">
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6 col-xl-5">
          <div className="card shadow mt-5">
            <div className="card-body position-relative">
              {/* Logo Component */}
              <div className="d-flex justify-content-center  mt-4">
                <Logo
                  redirect={false}
                  containerClassName="image"
                  imageHeight={40}
                  imageURL={URL_IMG_LOGO}
                />
              </div>

              <div className="p-2 pt-5">
                <LoginForm setValidationErr={setValidationErr} />
                <LoginError validationErr={validationErr} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
