import { all, fork } from 'redux-saga/effects';

// Auth Saga
import AuthSaga from './authSaga';

// Data Files Saga
import DatafileSaga from '../sagas/datafile/datafileSaga';

// Data Set Saga
import DatasetSaga from '../sagas/dataset/datasetSaga';

// Root Saga
export default function* RootSaga() {
  yield all([
    // App specific sagas go here..

    // Datafile saga
    fork(DatafileSaga),

    // Dataset Saga
    fork(DatasetSaga),

    // Auth Saga
    fork(AuthSaga),
  ]);
}
