/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';

// Actions
import { DatafileActions } from '../../store/slices/datafile/datafileSlice';

// constants
import { COLUMN_FILTER_TYPES } from '../../constants/datasetConstants';

// Hooks
import useUpdateQueryParams from '../../hooks/useUpdateQueryParams';

// Components
import SkeletonTable from '../../components/antd/table/SkeletonTable';
import DataTable from '../../components/antd/table/DataTable';
import Button from '../../components/button/Button';
import GraphViewModal from '../../components/modal/GraphViewModal';
import FilterTags from '../../components/filter/FilterTags';

function Header() {
  const { isRightSideOpen, setIsRightSideOpen } = useOutletContext();
  const datafileName = useSelector((state) => state.datafile.datafileName);

  return (
    <div className="d-flex justify-content-between align-items-center mb-5">
      <h2 className="p-0 m-0"> Datafile : {datafileName}</h2>
      {!isRightSideOpen && (
        <Button
          className="btn btn-primary"
          onClick={() => setIsRightSideOpen(true)}
        >
          Analyze
        </Button>
      )}
    </div>
  );
}

function DataFileTable({ datafileId }) {
  const dispatch = useDispatch();

  const data =
    useSelector((state) => state.datafile.datafileMap[datafileId]) || {};

  const filteredData =
    useSelector((state) => state.datafile.filteredDataMap[datafileId]) || {};

  const filterConfig =
    useSelector((state) => state.datafile.dataFilterConfig[datafileId]) || {};

  const isFiltersApplied = useSelector(
    (state) => state.datafile.isFiltersApplied,
  );

  const { columns, rows } = isFiltersApplied ? filteredData : data;

  function onRemoveFilterAction(filterOnColumn) {
    dispatch(
      DatafileActions.removeFilter({
        datafileId,
        column: filterOnColumn,
        tagType: COLUMN_FILTER_TYPES.SEARCH,
      }),
    );
  }

  return (
    <div>
      {isFiltersApplied && (
        <FilterTags
          filterConfig={filterConfig}
          onRemoveFilterAction={onRemoveFilterAction}
        />
      )}
      <DataTable columns={columns} rows={rows} applyOnlyTableBorder={true} />
    </div>
  );
}

export default function DatafileViewPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { datasetId, datafileId } = useParams();

  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Selectors
  const datafileLoading = useSelector(
    (state) => state.datafile.datafileLoading,
  );

  const { rows = [], columns = [] } =
    useSelector((state) => state.datafile.datafileMap[datafileId]) || {};

  useEffect(() => {
    // To update the query params graph type as null
    const params = {
      graphType: null,
    };
    updateQueryParams({ params });

    // Fetch the datafile data
    dispatch(DatafileActions.getDatafile({ datasetId, datafileId }));
    dispatch(DatafileActions.getDatafileInfo({ datasetId, datafileId }));
  }, [dispatch, datafileId]);

  return (
    <div className="px-5">
      {/** Datafile Loading */}
      {datafileLoading && <SkeletonTable columnCount={7} rowCount={10} />}

      {/** View Datafile Data */}
      {!datafileLoading && (
        <>
          <Header />
          <DataFileTable datafileId={datafileId} />
        </>
      )}

      <GraphViewModal rows={rows} columns={columns} />
    </div>
  );
}
