/**
 * General Constants here
 */

// Local Storage Keys
export const LocalStorageKeys = {
  USER_ID: 'USER_ID',
  AUTH_ORG: 'AUTH_ORG',
  TOKEN: 'TOKEN',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
};

// FileTypes
export const FileTypes = {
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV: 'text/csv',
};
