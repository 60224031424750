// Constants
import { COLUMN_CONFIG } from '../../../constants/datasetConstants';

export function getTransformedDataset(datasetObj) {
  // Response from the API
  const { id = '', name = '', description = '' } = datasetObj || {};

  // Setting the values in the reducer
  const dataset = { id: id, name: name, description: description };
  return dataset;
}

export function getTransformedDatasetList(datasetListObj) {
  // Response from the API
  const { items } = datasetListObj || {};

  const datasetLists = items.map((dataset) => {
    const { id = '', name = '', description = '' } = dataset;
    const datasets = {
      id: id,
      name: name,
      description: description,
    };
    return datasets;
  });

  // Setting the values in the reducer
  return datasetLists;
}

export function getTransformedDatasetColumnsDefinitions(columnsListObj) {
  // Response from the API
  const { items } = columnsListObj || {};

  const columnsDefList = items.map((columnDef) => {
    const {
      id = '',
      name = '',
      description = '',
      order = '',
      units = '',
      isMandatory = false,
      isSearchable = false,
      isSortable = false,
      isDerived = false,
      valueType = '',
      format = '',
      minBound = 0,
      maxBound = 0,
      allowedValueCSV = '',
      remarks = '',
    } = columnDef;
    const columns = {
      id: id,
      name: name,
      description: description,
      order: order,
      units: units,
      isMandatory: isMandatory,
      isSearchable: isSearchable,
      isSortable: isSortable,
      isDerived: isDerived,
      valueType: valueType,
      format: format,
      minBound: minBound,
      maxBound: maxBound,
      allowedValueCSV: allowedValueCSV,
      remarks: remarks,
    };
    return columns;
  });

  // Setting the values in the reducer
  return columnsDefList;
}

export function getTransformedDatasetColumns(datasetColumns) {
  const datasetColumnMap = {};
  for (const column of datasetColumns) {
    const { isSortable = false, isSearchable = false, name } = column;
    datasetColumnMap[name] = {
      [COLUMN_CONFIG.IS_SORTABLE]: isSortable,
      [COLUMN_CONFIG.IS_SEARCHABLE]: isSearchable,
    };
  }
  return datasetColumnMap;
}
