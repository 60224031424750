import { Dropdown } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  ChevronDown,
  CircleUserRound,
  LogOut,
  Settings,
  UserRound,
} from 'lucide-react';

// Images
import DatumLogo from '../../assets/images/logo/datum.png';

// Actions
import { AuthActions } from '../../store/slices/authSlice';

// Constants
import PageUrls from '../../constants/pageUrls';

// Dropdown Arrow Style Constants
const dropdownArrowStyle = {
  pointAtCenter: true,
};

// User Profile Menu
function ProfileMenu() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(AuthActions.logout());
    navigate(PageUrls.LoginPage);
  };

  const profileMenuOptions = {
    items: [
      {
        key: '1',
        label: 'Profile',
        icon: <UserRound />,
        onClick: () => {},
        className: 'p-2 px-3',
      },
      {
        key: '2',
        label: 'Settings',
        icon: <Settings />,
        onClick: () => {},
        className: 'p-2 px-3',
      },
      {
        key: '3',
        label: 'Logout',
        icon: <LogOut />,
        onClick: logout,
        className: 'p-2 px-3',
      },
    ],
  };
  return (
    <Dropdown
      menu={profileMenuOptions}
      className="cursor-pointer py-2 profile-menu"
      placement="bottomRight"
      arrow
    >
      <a
        className="d-flex justify-content-center align-items-center text-decoration-none topbar-profile"
        onClick={(e) => e.preventDefault()}
      >
        {/* User Profile Name */}
        <p className="fw-semibold m-0 p-0 me-3">Manoj</p>

        {/* User Profile Icon */}
        <CircleUserRound size={30} />

        {/* Profile Menu DropDown */}
        <ChevronDown size={24} />
      </a>
    </Dropdown>
  );
}

/**
 * Topnav Menu
 */
export default function TopnavMenu() {
  return (
    <div className="px-5 d-flex justify-content-between w-100 align-items-center">
      <img src={DatumLogo} alt="Datum Logo" />

      <ProfileMenu />
    </div>
  );
}
