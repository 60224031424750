import { combineReducers } from 'redux';

// Auth Reducer
import { AuthReducer } from '../slices/authSlice';

// Error Reducer
import { ErrorReducer } from '../slices/errorSlice';

// DataFiles Reducer
import { DatafileReducer } from '../slices/datafile/datafileSlice';

// DataSet Reducer
import { DatasetReducer } from '../slices/dataset/datasetSlice';

// Root Reducer definition
export const RootReducer = combineReducers({
  // App specific reducers go here..
  // DataFiles Reducer
  datafile: DatafileReducer,

  // DataSet Reducer
  dataset: DatasetReducer,

  // Auth Reducer
  auth: AuthReducer,

  // Error Reducer
  error: ErrorReducer,
});
