import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom/dist';

// Components
// import PageHeader from '../../components/layouts/PageHeader';

/**
 * Dashboard Page
 */
export default function DashboardPage() {
  // Outlet Context
  // eslint-disable-next-line no-unused-vars
  const { setHeaderComponent } = useOutletContext();

  // useEffect to update Page Header for specific pages
  useEffect(() => {
    // custom PageHeader :: (uncomment when needed)
    // setHeaderComponent(<PageHeader title="Page Header" />);
  }, []);

  return (
    <div className="page-content">
      <h3 className=" p-0">Dashboard</h3>
    </div>
  );
}
