import SidebarMenu from '../components/layouts/SidebarMenu';
import {
  LEFT_SIDEBAR_MENU_LIST,
  LEFT_SIDEBAR_MENU_NAVIGATION_CONFIG,
} from '../constants/layoutConstants';

// Left Sidebar Config
function getLeftSidebarConfig() {
  return {
    isSidebarCollapsible: true,
    menuComponent: (
      <SidebarMenu
        sidebarMenuList={LEFT_SIDEBAR_MENU_LIST}
        sidebarMenuNavigationConfig={LEFT_SIDEBAR_MENU_NAVIGATION_CONFIG}
      />
    ),
  };
}

// Top bar Config
// default will be added here for all same RoutePages
// custom header can be added from page itself using hook : useOutletContext
function getTobBarConfig(pageHeader, containerClassName = '') {
  return {
    menuComponent: pageHeader,
    containerClassName,
  };
}

const LayoutConfig = {
  getLeftSidebarConfig,
  getTobBarConfig,
};

export default LayoutConfig;
