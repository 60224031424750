// Datafile Graph Ploting Data
export const GRAPH_DATA = {
  Line: {
    trace: {
      x: [],
      y: [],
      type: 'scatter',
    },
  },
  Bar: {
    trace: {
      x: [],
      y: [],
      type: 'bar',
    },
  },
  Pie: {
    trace: {
      values: [],
      labels: [],
      type: 'pie',
    },
  },
};

// Default config for the graph
export const DEFAULT_CONFIG = {
  scrollZoom: false,
  staticPlot: false,
  displayModeBar: true,
  responsive: false,
};

// Graph Types for trace
export const GRAPH_TYPES = {
  Line: 'Line',
  Bar: 'Bar',
  Pie: 'Pie',
};
