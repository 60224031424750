// Components
import ErrorHandler from '../components/hoc/ErrorHandler';
import ProtectedRoute from '../components/hoc/ProtectedRoute';

// Constants
import PageUrls from '../constants/pageUrls';

// Pages
import DashboardPage from '../pages/dashboard/DashboardPage';
import DatasetListPage from '../pages/dataset-list/DatasetListPage';
import DatasetDetailsPage from '../pages/dataset/DatasetDetailsPage';
import DatasetViewPage from '../pages/dataset/DatasetViewPage';

// Layout
import MainPageLayout from './MainPageLayout';

// Main Pages and Layouts
export const MainPageRoutes = {
  path: PageUrls.DashboardPage,
  element: (
    // ErrorHandler
    <ErrorHandler>
      {/* ProtectedRoute */}
      <ProtectedRoute>
        {/* Layout */}
        <MainPageLayout />
      </ProtectedRoute>
    </ErrorHandler>
  ),
  children: [
    {
      path: PageUrls.DashboardPage,
      element: <DashboardPage />,
    },

    {
      path: PageUrls.DatasetListPage,
      element: <DatasetListPage />,
    },

    {
      path: PageUrls.DatasetViewPage,
      element: <DatasetViewPage />,
    },

    {
      path: PageUrls.DatasetDetailsPage,
      element: <DatasetDetailsPage />,
    },
  ],
};
