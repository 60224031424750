import { useEffect, useState } from 'react';
import { Form, Modal, Select } from 'antd';
import Plot from 'react-plotly.js';

// Constants
import { DEFAULT_CONFIG, GRAPH_TYPES } from '../../constants/chartConstants';

// Ḥooks
import useUpdateQueryParams from '../../hooks/useUpdateQueryParams';

// Components
import Button from '../button/Button';

// Modal Body Form Function
function ModalBodyForm({ form = {}, options = [], onFinish = () => {} }) {
  return (
    <Form layout="horizontal" form={form} className="p-4">
      <div className="w-100 row gap-5">
        <Form.Item
          name="xAxis"
          label="X-axis"
          className="fw-semibold col m-0 p-0"
        >
          <Select>
            {options?.map((option, index) => {
              return (
                <Select.Option key={index} value={option}>
                  {option}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="yAxis"
          label="Y-axis"
          className="fw-semibold col p-0 m-0"
        >
          <Select>
            {options?.map((option, index) => {
              return (
                <Select.Option key={index} value={option}>
                  {option}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        <Button
          type="submit"
          className="btn btn-primary col-2"
          onClick={onFinish}
        >
          Visualize
        </Button>
      </div>
    </Form>
  );
}

/**
 * Graph View Modal
 */
export default function GraphViewModal({ rows, columns }) {
  const headers = columns.map((column) => column.title);

  // states
  const [openModal, setOpenModal] = useState(false);
  const [options, setOptions] = useState([]);
  const [trace, setTrace] = useState({});

  const [searchParams, updateQueryParams] = useUpdateQueryParams();
  const graphType = searchParams.get('graphType') || '';

  const [form] = Form.useForm();

  // Function to show the graph after clicking the btn
  function onFinish() {
    // taking x and y axis index from the form
    const { xAxis, yAxis } = form.getFieldsValue();

    // getting the x and y data for trace
    const xData = rows.map((x) => parseFloat(x[xAxis]));
    const yData = rows.map((y) =>
      graphType === GRAPH_TYPES.Pie ? y[yAxis] : parseFloat(y[yAxis]),
    );
    const traceType = graphType.toLowerCase();

    // constructing the trace for plotting
    // checking for graph type "pie" because it needs values and labels key
    const trace =
      graphType === GRAPH_TYPES.Pie
        ? { values: xData, labels: yData, type: traceType }
        : { x: xData, y: yData, type: traceType };

    setTrace(trace);
  }

  const handleReset = () => {
    form.resetFields();
    const params = {
      graphType: null,
    };
    updateQueryParams({ params });
    setTrace({});
  };

  useEffect(() => {
    setOptions(headers);
    if (graphType) {
      setOpenModal(true);
      return;
    }
    setOpenModal(false);
  }, [graphType]);
  return (
    <Modal
      open={openModal}
      title={`${graphType} Chart View`}
      onCancel={() => setOpenModal(false)}
      width={1000}
      footer={false}
      onFinish={onFinish}
      afterClose={handleReset}
    >
      {/* Form to select the x and y axis */}
      <ModalBodyForm form={form} options={options} onFinish={onFinish} />

      <div className="mt-5 w-100 d-flex justify-content-center align-items-center">
        <Plot
          data={[trace]}
          layout={{ width: 800, height: 500 }}
          config={DEFAULT_CONFIG}
        />
      </div>
    </Modal>
  );
}
