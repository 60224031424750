import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Actions
import { DatasetActions } from '../../store/slices/dataset/datasetSlice';

// Components
import SkeletonTable from '../../components/antd/table/SkeletonTable';
import DatasetAddEditColumnModal from './components/DatasetAddEditColumnModal';
import DatasetColumnDefinitions from './components/DatasetColumnDefinitions';

/**
 * Dataset Deatils View Page
 * @returns
 */
export default function DatasetDetailsPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Getting datasetId from the params
  const { datasetId } = useParams();

  // States for modal open and to handle data for edit
  const [openModal, setOpenModal] = useState(false);
  const [columnData, setColumnData] = useState({});

  // Selectors
  const getDatasetLoading = useSelector(
    (state) => state.dataset.getDatasetLoading,
  );
  const dataset = useSelector((state) => state.dataset.dataset);

  // Getting the name from dataset
  const { name = '' } = dataset;

  // Dispatch to get dataset detail and list of columns for the dataset
  useEffect(() => {
    dispatch(DatasetActions.getDataset({ datasetId }));
    dispatch(DatasetActions.listColumns({ datasetId }));
  }, [dispatch, datasetId]);
  return (
    <div className="page-content">
      {getDatasetLoading && <SkeletonTable columnCount={5} rowCount={5} />}
      {!getDatasetLoading && (
        <DatasetColumnDefinitions
          name={name}
          setOpenModal={setOpenModal}
          setColumnData={setColumnData}
        />
      )}

      <DatasetAddEditColumnModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        columnData={columnData}
        setColumnData={setColumnData}
        datasetId={datasetId}
      />
    </div>
  );
}
