// Layouts
import Layout from '../components/layouts/Layout';

// Components
import TopnavMenu from '../components/layouts/TopnavMenu';
import LayoutConfig from './LayoutConfig';

/**
 * Main Page Layout
 */
export default function MainPageLayout() {
  // State
  const topbarConfig = LayoutConfig.getTobBarConfig(<TopnavMenu />);
  const leftSidebarConfig = LayoutConfig.getLeftSidebarConfig();

  return (
    <Layout topbarConfig={topbarConfig} leftSidebarConfig={leftSidebarConfig} />
  );
}
