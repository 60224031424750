import { createSlice } from '@reduxjs/toolkit';

// Utils
import DataTableUtils from '../../../utils/dataTableUtils';

// Transformers
import {
  getTransformedDataset,
  getTransformedDatasetColumns,
  getTransformedDatasetColumnsDefinitions,
  getTransformedDatasetList,
} from './datasetTransformer';

// initial state
const initialState = {
  // list Dataset State
  datasets: [],
  listDatasetLoading: false,
  listDatasetError: {},

  // Dataset state
  datasetId: '',
  upsertDatasetLoading: false,
  upsertDatasetSuccess: false,
  upsertDatasetError: {},

  // Dataset fetch state
  dataset: {},
  getDatasetLoading: false,
  getDatasetError: {},

  // Column Definitons States
  // list dataset columns state
  columns: [],
  columnLoading: false,
  listColumnsError: {},

  // Upsert Column State
  upsertColumnLoading: false,
  upsertColumnSuccess: false,
  upsertColumnError: {},

  // column fetch state
  column: {},
  getColumnError: {},

  // Dataset column config state
  columnConfig: {},
  columnConfigLoading: false,
  columnConfigError: {},
};

const datasetSlice = createSlice({
  name: 'Dataset',
  initialState,
  reducers: {
    // Create Dataset
    createDataset: (state) => {
      // Resetting
      state.datasetId = '';
      state.upsertDatasetError = '';
      state.upsertDatasetSuccess = false;
      state.upsertDatasetLoading = true;
    },

    createDatasetSuccess: (state, action) => {
      state.upsertDatasetLoading = false;
      state.upsertDatasetSuccess = true;
      state.datasetId = action.payload.datasetId;
    },

    createDatasetFailure: (state, action) => {
      state.upsertDatasetLoading = false;
      state.upsertDatasetSuccess = false;
      state.upsertDatasetError = action.payload.error;
    },

    // Update Dataset
    updateDataset: (state) => {
      state.upsertDatasetLoading = true;
      state.upsertDatasetSuccess = false;
      state.upsertDatasetError = {};
    },

    updateDatasetSuccess: (state) => {
      state.upsertDatasetLoading = false;
      state.upsertDatasetSuccess = true;
    },

    updateDatasetFailure: (state, action) => {
      state.upsertDatasetLoading = false;
      state.upsertDatasetSuccess = false;
      state.upsertDatasetError = action.payload.error;
    },

    getDataset: (state) => {
      state.getDatasetError = {};
      state.getDatasetLoading = true;
      state.dataset = {};
    },

    getDatasetSuccess: (state, action) => {
      state.getDatasetLoading = false;
      const { dataset } = action.payload;
      state.dataset = getTransformedDataset(dataset);
    },

    getDatasetFailure: (state, action) => {
      state.getDatasetError = action.payload.error;
      state.getDatasetLoading = false;
    },

    listDatasets: (state) => {
      state.listDatasetError = '';
      state.listDatasetLoading = true;
    },

    listDatasetsSuccess: (state, action) => {
      state.listDatasetLoading = false;

      const { datasetListObj } = action.payload;
      const datasets = getTransformedDatasetList(datasetListObj);
      state.datasets = DataTableUtils.constructDatasetTableData(datasets);
    },

    listDatasetsFailure: (state, action) => {
      state.listDatasetLoading = false;
      state.listDatasetError = action.payload.error;
    },

    // Dataset Columns Definition

    // Create Column Definitions
    createColumn: (state) => {
      // Resetting
      state.upsertColumnLoading = true;
      state.upsertColumnSuccess = false;
      state.upsertColumnError = {};
    },

    createColumnSuccess: (state) => {
      state.upsertColumnLoading = false;
      state.upsertColumnSuccess = true;
    },

    createColumnFailure: (state, action) => {
      state.upsertColumnLoading = false;
      state.upsertColumnSuccess = false;
      state.upsertColumnError = action.payload.error;
    },

    // Update Column Definition
    updateColumn: (state) => {
      state.upsertColumnLoading = true;
      state.upsertColumnSuccess = false;
      state.upsertColumnError = {};
    },

    updateColumnSuccess: (state) => {
      state.upsertColumnLoading = false;
      state.upsertColumnSuccess = true;
    },

    updateColumnFailure: (state, action) => {
      state.upsertColumnLoading = false;
      state.upsertColumnSuccess = false;
      state.upsertColumnError = action.payload.error;
    },

    // Get Column Definition
    getColumn: (state) => {
      state.columnLoading = true;
      state.getColumnError = {};
      state.column = {};
    },

    getColumnSuccess: (state, action) => {
      state.columnLoading = false;
      state.column = action.payload.column;
    },

    getColumnFailure: (state, action) => {
      state.columnLoading = false;
      state.getColumnError = action.payload.error;
    },

    // List Column Definitions
    listColumns: (state) => {
      state.columnLoading = true;
      state.listColumnsError = {};
    },

    listColumnsSuccess: (state, action) => {
      state.columnLoading = false;
      state.listColumnsError = {};
      const { columnsObj } = action.payload;
      const columnItems = getTransformedDatasetColumnsDefinitions(columnsObj);
      state.columns =
        DataTableUtils.constructColumnsDefinitionTableData(columnItems);
    },

    listColumnsFailure: (state, action) => {
      state.columnLoading = false;
      state.listColumnsError = action.payload.error;
    },

    getDatasetColumns: (state) => {
      state.columnConfigError = '';
      state.columnConfigLoading = true;
    },

    getDatasetColumnsSuccess: (state, action) => {
      state.columnConfigLoading = false;

      const { datasetId, datasetColumns } = action.payload;
      state.columnConfig[datasetId] =
        getTransformedDatasetColumns(datasetColumns);
    },

    getDatasetColumnsFailure: (state, action) => {
      state.columnConfigError = action.payload.error;
      state.columnConfigLoading = false;
    },

    reset: () => initialState,
  },
});

// Reducer
export const DatasetReducer = datasetSlice.reducer;

// Actions
export const DatasetActions = datasetSlice.actions;
