// React Related Imports
import { all, put, select, takeLatest } from 'redux-saga/effects';

// Service
import DatafileService from '../../../services/datafile/datafileService';
import Datasetservice from '../../../services/dataset/datasetService';

// constants
import { DefaultPagination } from '../../../constants/webConstants';

// Actions
import { DatafileActions } from '../../slices/datafile/datafileSlice';

// Transformers
import { getTransformedDatasetColumns } from '../../slices/dataset/datasetTransformer';

function* getDatasetColumnsConfig(datasetId) {
  // Get the column config from the reducer..
  // If does not exits then explicitly call it..
  // NOTE: We need this when user reloads the page
  const columnConfigMap = yield select((state) => state.dataset.columnConfig);
  let columnConfig = columnConfigMap[datasetId] || {};
  if (Object.keys(columnConfig).length === 0) {
    const { items } = yield Datasetservice.getDatasetColumns(datasetId);
    columnConfig = getTransformedDatasetColumns(items);
  }

  return columnConfig;
}

function* getDatafile(action) {
  try {
    const { datasetId, datafileId } = action.payload;

    // Call API
    // ------------------------------------
    const datafileObj = yield DatafileService.getDatafile(
      datasetId,
      datafileId,
    );

    // Get the dataset columns config
    const columnConfig = yield getDatasetColumnsConfig(datasetId);

    yield put(
      DatafileActions.getDatafileSuccess({
        datafileId,
        columnConfig,
        datafileObj,
      }),
    );
  } catch (error) {
    // TODO: Configure the error
    yield put(DatafileActions.getDatafileFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

function* getDatafileInfo(action) {
  try {
    const { datasetId, datafileId } = action.payload;

    // Call API
    // ------------------------------------
    const datafileInfo = yield DatafileService.getDatafileInfo(
      datasetId,
      datafileId,
    );

    yield put(
      DatafileActions.getDatafileInfoSuccess({
        datafileInfo,
      }),
    );
  } catch (error) {
    // TODO: Configure the error
    yield put(DatafileActions.getDatafileInfoFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

function* uploadDatafile(action) {
  try {
    const { datasetId, datafileObj } = action.payload;

    // Call API
    // ------------------------------------
    yield DatafileService.uploadDatafile(datasetId, datafileObj);

    // Fetch datafiles list to update the newly added file..
    const pageNumber = DefaultPagination.pageNumber;
    const pageSize = DefaultPagination.maxPageSize;
    yield put({
      type: DatafileActions.listDatafiles.type,
      payload: { datasetId, pageNumber, pageSize },
    });

    // dispatching action
    yield put(DatafileActions.uploadDatafileSuccess());
  } catch (error) {
    // TODO: Configure the error
    yield put(DatafileActions.uploadDatafileFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

function* listDatafiles(action) {
  try {
    const {
      datasetId,
      pageNumber = DefaultPagination.pageNumber,
      pageSize = DefaultPagination.pageSize,
    } = action.payload;

    // Call API
    // ------------------------------------
    const datafilesObj = yield DatafileService.listDatafiles(
      datasetId,
      pageNumber,
      pageSize,
    );

    // dispatching action
    yield put(
      DatafileActions.listDatafilesSuccess({ datasetId, datafilesObj }),
    );
  } catch (error) {
    // TODO: Configure the error
    yield put(DatafileActions.listDatafilesFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

/**
 * Auth Sagas
 */
export default function* root() {
  yield all([
    takeLatest(DatafileActions.listDatafiles.type, listDatafiles),
    takeLatest(DatafileActions.uploadDatafile.type, uploadDatafile),
    takeLatest(DatafileActions.getDatafile.type, getDatafile),
    takeLatest(DatafileActions.getDatafileInfo.type, getDatafileInfo),
  ]);
}
