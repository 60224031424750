// App Client
import HttpClientFactory from '../../utils/httpClientFactory';

// Utils
import UrlUtils from '../../utils/urlUtils';

// Urls
import {
  DatasetColumnDefinitionUrls,
  DatasetUrls,
} from '../../constants/serviceUrls';

/**
 * Create new Dataset
 * @param {*} datasetObj
 * @returns
 */
async function createDataset(datasetObj) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(DatasetUrls.createDataset, {}, datasetObj);
}

/**
 * update existing dataset
 *
 * @param {*} datasetId
 * @param {*} datasetObj
 * @returns
 */
async function updateDataset(datasetId, datasetObj) {
  const url = UrlUtils.format(DatasetUrls.updateDataset, { datasetId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, {}, datasetObj);
}

/**
 * Get Dataset details
 * @param {*} datasetId
 * @returns
 */
async function getDataset(datasetId) {
  const url = UrlUtils.format(DatasetUrls.viewDataset, { datasetId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url);
}

/**
 * List Datasets.
 * @param {*} pageNumber
 * @param {*} pageSize
 * @returns
 */
async function listDatasets(pageNumber, pageSize) {
  const params = {
    pn: pageNumber,
    ps: pageSize,
  };
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(DatasetUrls.listDatasets, params);
}

async function getDatasetColumns(datasetId) {
  const url = UrlUtils.format(DatasetUrls.getDatasetColumns, { datasetId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url);
}

/**
 * Create Column Definitions.
 * @param {*} datasetId
 * @param {*} columnObj
 * @returns
 */
async function createColumn(datasetId, columnObj) {
  const url = UrlUtils.format(DatasetColumnDefinitionUrls.createColumn, {
    datasetId,
  });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(url, {}, columnObj);
}

// update the existing column definitions
async function updateColumn(datasetId, columnId, columnObj) {
  const url = UrlUtils.format(DatasetColumnDefinitionUrls.updateColumn, {
    datasetId,
    columnId,
  });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, {}, columnObj);
}

// List columns definitions
async function listColumns(datasetId) {
  const url = UrlUtils.format(DatasetColumnDefinitionUrls.getColumns, {
    datasetId,
  });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url);
}

const Datasetservice = {
  createDataset,
  updateDataset,
  getDataset,
  listDatasets,
  getDatasetColumns,
  createColumn,
  updateColumn,
  listColumns,
};

export default Datasetservice;
