//Auth Service Urls
export const AuthUrls = { login: '/api/v1/auth/token' };

//Data File Urls
export const DatafileUrls = {
  uploadDatafile: '/api/v1/dataset/:datasetId/file',
  getDatafile: '/api/v1/dataset/:datasetId/file/data',
  getDatafileInfo: '/api/v1/dataset/:datasetId/file/:datafileId',
  listDatafiles: '/api/v1/dataset/:datasetId/file',
};

// Dataset Urls
export const DatasetUrls = {
  createDataset: '/api/v1/dataset',
  viewDataset: '/api/v1/dataset/:datasetId',
  updateDataset: '/api/v1/dataset/:datasetId',
  listDatasets: '/api/v1/dataset',
  getDatasetColumns: '/api/v1/dataset/:datasetId/column',
};

export const DatasetColumnDefinitionUrls = {
  createColumn: '/api/v1/dataset/:datasetId/column',
  viewColumn: '/api/v1/dataset/:datasetId/column/:columnId',
  updateColumn: '/api/v1/dataset/:datasetId/column/:columnId',
  getColumns: '/api/v1/dataset/:datasetId/column',
};
