// Page paths
const PagePaths = {
  Auth: '/auth',
  Error: '/error',
};

// URLs
const PageUrls = {
  // Auth Prefix
  // Login Page
  LoginPage: `${PagePaths.Auth}/login`,
  ForgotPasswordPage: `${PagePaths.Auth}/forgot-password`,

  // Home Page
  DashboardPage: '/',

  // Dataset Pages
  DatasetListPage: '/datasets',
  DatasetViewPage: '/datasets/:datasetId',
  DatasetDetailsPage: '/datasets/:datasetId/details',

  // Datafile Page
  DatafileViewPage: '/datasets/:datasetId/datafiles/:datafileId',

  // Error Page
  ErrorPage: `${PagePaths.Error}/:errorStatusCode`,

  // Custom Error Page Urls..
  CustomErrorPage: `${PagePaths.Error}`,
};

export default PageUrls;
