import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Eye, Info, Pencil } from 'lucide-react';

// Constants
import PageUrls from '../../../constants/pageUrls';
import { DATASET_TABLE_HEADER } from '../../../constants/datasetConstants';

// Utils
import UrlUtils from '../../../utils/urlUtils';

// Components
import Button from '../../../components/button/Button';
import DataTable from '../../../components/antd/table/DataTable';

// Page Components
function CreateDatasetButton({
  onClick = () => {},
  loading = false,
  disabled = false,
}) {
  return (
    <Button
      className="btn-primary px-2"
      loading={loading}
      disabled={disabled}
      onClick={onClick}
    >
      New dataset
    </Button>
  );
}

// Dataset Table Actions Function
function DatasetTableActions({ record = {}, editBtnClickFn = () => {} }) {
  // Navigate
  const navigate = useNavigate();

  const { key: datasetId } = record;
  const redirectPath = UrlUtils.format(PageUrls.DatasetViewPage, {
    datasetId,
  });

  function navigateToDatasetViewPage() {
    const url = UrlUtils.format(PageUrls.DatasetDetailsPage, {
      datasetId,
    });
    navigate(url);
  }

  return (
    <div className="d-flex gap-4 align-items-center">
      <Pencil
        className="cursor-pointer"
        size={16}
        onClick={() => editBtnClickFn(record)}
      >
        <title>Edit</title>
      </Pencil>

      <Info
        size={16}
        className="cursor-pointer"
        onClick={navigateToDatasetViewPage}
      >
        <title>Info</title>
      </Info>

      <Eye
        size={16}
        className="cursor-pointer"
        onClick={() => navigate(redirectPath)}
      >
        <title>View</title>
      </Eye>
    </div>
  );
}

/**
 * Dataset Table
 * @param {*} setOpenModal : Function
 * @param {*} setEditDatasetData : Function
 * @returns
 */
export default function DatasetTable({
  setOpenModal = false,
  setEditDatasetData = () => {},
}) {
  const [columns, setColumns] = useState([]);
  const datasets = useSelector((state) => state.dataset.datasets);
  const datasetsLoading = useSelector(
    (state) => state.dataset.listDatasetLoading,
  );

  function openDatasetEditModal(record = {}) {
    setOpenModal(true);
    setEditDatasetData(record);
  }

  // Constructing columns for dataset table
  const actionColumn = {
    title: 'Action',
    key: 'action',
    render: (record) => (
      <DatasetTableActions
        record={record}
        editBtnClickFn={openDatasetEditModal}
      />
    ),
  };

  useEffect(() => {
    setColumns([...DATASET_TABLE_HEADER, actionColumn]);
  }, []);
  return (
    <>
      <div className="d-flex justify-content-end my-4 p-0">
        <CreateDatasetButton
          loading={datasetsLoading}
          disabled={datasetsLoading}
          onClick={openDatasetEditModal}
        />
      </div>
      <DataTable
        rows={datasets}
        columns={columns}
        applyOnlyTableBorder={true}
        className="dataset-list-table"
      />
    </>
  );
}
