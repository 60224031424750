import { X } from 'lucide-react';

// CSS
import './FilterTags.scss';

function Tag({ tagValue, onColumn, onRemoveFilterAction }) {
  return (
    <div className="d-flex tag">
      <span className="tag-value">{tagValue}</span>
      <X
        className="cursor-pointer close-tag"
        size={16}
        onClick={() => onRemoveFilterAction(onColumn)}
      />
    </div>
  );
}

export default function FilterTags({ filterConfig, onRemoveFilterAction }) {
  return (
    <div className="d-flex mb-2">
      {Object.entries(filterConfig).map(([key, value], index) => {
        const { filterTag } = value;
        return (
          <Tag
            key={index}
            tagValue={filterTag}
            onColumn={key}
            onRemoveFilterAction={onRemoveFilterAction}
          />
        );
      })}
    </div>
  );
}
