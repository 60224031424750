import { Input, InputNumber, Select, Switch } from 'antd';

export const COLUMN_CONFIG = {
  NAME: 'name',
  IS_SORTABLE: 'isSortable',
  IS_SEARCHABLE: 'isSearchable',
};

export const COLUMN_FILTER_TYPES = {
  SEARCH: 'SEARCH',
};

export const DATASET_TABLE_HEADER = [
  {
    title: 'S.No',
    dataIndex: 'sno',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
];

// Form input data for adding and editing column
export const DATASET_COLUMN_FORM_INPUT = [
  {
    id: 1,
    label: 'Name :',
    name: 'name',
    className: 'fw-semibold',
    rules: [{ required: true, message: 'Name is Required' }],
    help: '',
    input: <Input className="ms-3" />,
  },
  {
    id: 2,
    label: 'Description :',
    name: 'description',
    className: 'fw-semibold',
    input: <Input className="ms-3" />,
  },
  {
    id: 3,
    label: 'Order :',
    name: 'order',
    className: 'col fw-semibold',
    input: <InputNumber className="ms-3" />,
  },
  {
    id: 4,
    label: 'Units :',
    name: 'units',
    className: 'fw-semibold',
    input: <Input className="ms-3" />,
  },
  {
    id: 5,
    label: 'isMandatory :',
    name: 'isMandatory',
    className: 'fw-semibold',
    valuePropName: 'checked',
    input: <Switch className="ms-3" />,
  },
  {
    id: 6,
    label: 'isSearchable :',
    name: 'isSearchable',
    className: 'fw-semibold',
    valuePropName: 'checked',
    input: <Switch className="ms-3" />,
  },
  {
    id: 7,
    label: 'isSortable :',
    name: 'isSortable',
    className: 'fw-semibold',
    valuePropName: 'checked',
    input: <Switch className="ms-3" />,
  },
  {
    id: 8,
    label: 'isDerived :',
    name: 'isDerived',
    valuePropName: 'checked',
    className: 'fw-semibold',
    input: <Switch className="ms-3" />,
  },
  {
    id: 9,
    label: 'Value Type :',
    name: 'valueType',
    className: 'fw-semibold',
    rules: [{ required: true, message: 'Value Type is Not Selected' }],
    help: '',
    input: (
      <Select className="ms-3">
        <Select.Option key={'INTEGER'} value={'INTEGER'}>
          INTEGER
        </Select.Option>
        <Select.Option key={'BOOLEAN'} value={'BOOLEAN'}>
          BOOLEAN
        </Select.Option>
        <Select.Option key={'FLOAT'} value={'FLOAT'}>
          FLOAT
        </Select.Option>
      </Select>
    ),
  },
  {
    id: 10,
    label: 'Format :',
    name: 'format',
    className: 'fw-semibold',
    input: <Input className="ms-3" />,
  },
  {
    id: 11,
    label: 'Minimum Bound :',
    name: 'minBound',
    className: 'fw-semibold',
    input: <InputNumber className="ms-3" />,
  },
  {
    id: 12,
    label: 'Maximum Bound :',
    name: 'maxBound',
    className: 'fw-semibold',
    input: <InputNumber className="ms-3" />,
  },
  {
    id: 13,
    label: 'Allowed Values :',
    name: 'allowedValuesCSV',
    className: 'fw-semibold',
    input: <Input className="ms-3" />,
  },
  {
    id: 14,
    label: 'Remarks :',
    name: 'remarks',
    className: 'fw-semibold',
    input: <Input className="ms-3" />,
  },
];

export const DATASET_COLUMNS_DEFINITIONS_HEADER = [
  {
    title: 'S. No.',
    dataIndex: 'sno',
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    fixed: 'left',
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
  {
    title: 'Order',
    dataIndex: 'order',
  },
  {
    title: 'Units',
    dataIndex: 'units',
  },
  {
    title: 'Mandatory',
    dataIndex: 'isMandatory',
  },
  {
    title: 'Searchable',
    dataIndex: 'isSearchable',
  },
  {
    title: 'Sortable',
    dataIndex: 'isSortable',
  },
  {
    title: 'Derived',
    dataIndex: 'isDerived',
  },
  {
    title: 'Data Type',
    dataIndex: 'valueType',
  },
  {
    title: 'Format',
    dataIndex: 'format',
  },
  {
    title: 'Min Value',
    dataIndex: 'minBound',
  },
  {
    title: 'Max Value',
    dataIndex: 'maxBound',
  },
  {
    title: 'Allowed Values',
    dataIndex: 'allowedValuesCSV',
  },
  {
    title: 'Remarks',
    dataIndex: 'remarks',
  },
];
