// React Related Imports
import { all, put, takeLatest } from 'redux-saga/effects';

// Service
import DatasetService from '../../../services/dataset/datasetService';

// constants
import { DefaultPagination } from '../../../constants/webConstants';

// Actions
import { DatasetActions } from '../../slices/dataset/datasetSlice';

function* createDataset(action) {
  try {
    const { datasetObj = {} } = action.payload;
    // Call API
    // ------------------------------------
    const datasetId = yield DatasetService.createDataset(datasetObj);

    // TODO: Do we need to dispatch this action here?
    // Once the new dataset is created we need to fetch the updated datasetList
    yield put({
      type: DatasetActions.listDatasets.type,
      payload: {
        pageNumber: DefaultPagination.pageNumber,
        pageSize: DefaultPagination.maxPageSize,
      },
    });

    // dispatching action
    yield put(DatasetActions.createDatasetSuccess({ datasetId }));
  } catch (error) {
    // TODO: Configure the error
    yield put(DatasetActions.createDatasetFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

// Update Dataset Details
function* updateDataset(action) {
  try {
    const { datasetId = '', datasetObj = {} } = action.payload;

    yield DatasetService.updateDataset(datasetId, datasetObj);

    yield put({
      type: DatasetActions.listDatasets.type,
      payload: {
        pageNumber: DefaultPagination.pageNumber,
        pageSize: DefaultPagination.maxPageSize,
      },
    });

    yield put(DatasetActions.updateDatasetSuccess());
  } catch (error) {
    yield put(DatasetActions.updateDatasetFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

function* getDataset(action) {
  try {
    const { datasetId } = action.payload;
    // Call API
    // ------------------------------------
    const dataset = yield DatasetService.getDataset(datasetId);

    // dispatching action
    yield put(DatasetActions.getDatasetSuccess({ dataset }));
  } catch (error) {
    // TODO: Configure the error
    yield put(DatasetActions.getDatasetFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

function* listDatasets(action) {
  try {
    const {
      pageNumber = DefaultPagination.pageNumber,
      pageSize = DefaultPagination.pageSize,
    } = action.payload;

    // Call API
    // ------------------------------------
    const datasetListObj = yield DatasetService.listDatasets(
      pageNumber,
      pageSize,
    );

    // dispatching action
    yield put(DatasetActions.listDatasetsSuccess({ datasetListObj }));
  } catch (error) {
    // TODO: Configure the error
    yield put(DatasetActions.listDatasetsFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

// Create New Column Definitions
function* createColumn(action) {
  const { datasetId, columnObj } = action.payload;
  try {
    yield DatasetService.createColumn(datasetId, columnObj);

    yield put({
      type: DatasetActions.listColumns.type,
      payload: {
        datasetId,
        pageNumber: DefaultPagination.pageNumber,
        pageSize: DefaultPagination.maxPageSize,
      },
    });

    yield put(DatasetActions.createColumnSuccess());
  } catch (error) {
    yield put(DatasetActions.createColumnFailure({ error: 'Error' }));
    console.log('Error:', error);
  }
}

// update the existing column definition
function* updateColumn(action) {
  const { datasetId, columnId, columnObj } = action.payload;

  try {
    yield DatasetService.updateColumn(datasetId, columnId, columnObj);

    yield put({
      type: DatasetActions.listColumns.type,
      payload: {
        datasetId,
        pageNumber: DefaultPagination.pageNumber,
        pageSize: DefaultPagination.maxPageSize,
      },
    });

    yield put(DatasetActions.updateColumnSuccess());
  } catch (error) {
    yield put(DatasetActions.updateColumnFailure({ error: 'Error' }));
    console.log('Error:', error);
  }
}

// List all Column Definitions
function* listColumns(action) {
  const { datasetId } = action.payload;
  try {
    const columnsObj = yield DatasetService.listColumns(datasetId);

    yield put(DatasetActions.listColumnsSuccess({ columnsObj }));
  } catch (error) {
    yield put(DatasetActions.listColumnsFailure({ error: 'Error' }));
    console.log('Error:', error);
  }
}

function* getDatasetColumns(action) {
  try {
    const { datasetId } = action.payload;

    // Call API
    // ------------------------------------
    const { items } = yield DatasetService.getDatasetColumns(datasetId);

    // dispatching action
    yield put(
      DatasetActions.getDatasetColumnsSuccess({
        datasetId,
        datasetColumns: items,
      }),
    );
  } catch (error) {
    // TODO: Configure the error
    yield put(DatasetActions.getDatasetColumnsFailure({ error: 'Error' }));
    console.log('Error :', error);
  }
}

/**
 * Auth Sagas
 */
export default function* root() {
  yield all([
    takeLatest(DatasetActions.createDataset.type, createDataset),
    takeLatest(DatasetActions.getDataset.type, getDataset),
    takeLatest(DatasetActions.listDatasets.type, listDatasets),
    takeLatest(DatasetActions.getDatasetColumns.type, getDatasetColumns),
    takeLatest(DatasetActions.updateDataset.type, updateDataset),
    takeLatest(DatasetActions.createColumn.type, createColumn),
    takeLatest(DatasetActions.updateColumn.type, updateColumn),
    takeLatest(DatasetActions.listColumns.type, listColumns),
  ]);
}
