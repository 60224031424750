import { Link as RouterLink } from 'react-router-dom';

// Css
import './Link.scss';

function constructClassname(disableTextUnderLine, className) {
  const modifierClassName = disableTextUnderLine ? 'custom-link' : '';
  return `btn btn-link ${className} ${modifierClassName}`;
}

/**
 * Link Component
 * @param {*} className
 * @param {*} label
 * @param {*} disableTextUnderLine : true/false
 * @param {*} linkTo : redirect Path
 */

export default function Link({
  className = '',
  label = '',
  disableTextUnderLine = true,
  linkTo = '',
}) {
  const linkClassName = constructClassname(disableTextUnderLine, className);
  return (
    <RouterLink className={linkClassName} to={linkTo}>
      {label}
    </RouterLink>
  );
}
