import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// TODO: Find a way to remove hardcode keys
function getMenuItemId(pathname, navigationConfig) {
  for (const [key, value] of Object.entries(navigationConfig)) {
    if (key !== '/' && pathname.includes(key)) {
      return value['menuItemId'];
    }

    if (pathname === '/' && key === pathname) {
      return value['menuItemId'];
    }
  }
  return '';
}

// TODO: Find a way to remove hardcode keys
function getRedirectUrl(menuItemId, navigationConfig) {
  for (const value of Object.values(navigationConfig)) {
    if (value['menuItemId'] === menuItemId) {
      return value['redirectUrl'];
    }
  }
  return '';
}

/**
 * This custom hook is use to set the functionality of menu item on click (selected)
 *
 * What it expects ==> navigationConfig
 * Format  ==> {label: {menuItmeId, redirectUrl}}
 * Example ==> {datasets: {menuItemId: 1, redirectUrl: "/datasets"}}
 *
 * Understanding of the format:
 * a) menuItmId ==> id of the each menu item (this id will be used to set the selected menu item)
 * b) label ==> this is used to identify the which menuItem should be selected from the pageUrl
 * c) redirectUrl ==> is used to navigate to the respective redirectUrl.
 *
 * @param {*} navigationConfig
 * @returns
 */
const useSelectedMenu = (navigationConfig, configKeys) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const menuItmId = getMenuItemId(pathname, navigationConfig, configKeys);
  const [selectedKey, setSelectedKey] = useState(menuItmId);

  useEffect(() => {
    setSelectedKey(menuItmId);
  }, [pathname, menuItmId]);

  const handleMenuClick = (id) => {
    const redirectUrl = getRedirectUrl(id, navigationConfig);
    setSelectedKey(id);
    navigate(redirectUrl);
  };

  return {
    selectedKey,
    handleMenuClick,
  };
};

export default useSelectedMenu;
