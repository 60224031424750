import { COLUMN_FILTER_TYPES } from '../constants/datasetConstants';

function filter(data, filterConfig) {
  const { rows = [], columns = [] } = data;
  const columnsOnAppliedFilters = Object.keys(filterConfig);

  if (columnsOnAppliedFilters.length === 0) {
    return { rows, columns };
  }

  const filteredRows = [];
  for (const row of rows) {
    let isRowMatched = true;

    for (const eachColumn of columnsOnAppliedFilters) {
      const { tagType, filterTag } = filterConfig[eachColumn];

      if (tagType === COLUMN_FILTER_TYPES.SEARCH) {
        const isMatched = row[eachColumn]
          .toString()
          .toLowerCase()
          .includes(filterTag.toLowerCase());

        if (!isMatched) {
          isRowMatched = false;
          break;
        }
      }
    }

    if (isRowMatched) {
      filteredRows.push(row);
    }
  }
  return { rows: filteredRows, columns };
}

const DatafileFilterUtils = {
  filter,
};

export default DatafileFilterUtils;
