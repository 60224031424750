import { Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { AuthActions } from '../../store/slices/authSlice';

// Components
import Button from '../../components/button/Button';

export default function LoginForm({ setValidationErr }) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const loginLoading = useSelector((state) => state.auth.loginLoading);

  // Function to handle the form submission
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const { username, password } = values;
        setValidationErr('');

        dispatch(AuthActions.login({ username, password }));
      })
      .catch((info) => {
        setValidationErr(info.errorFields[0].errors[0]);
      });
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label="Username"
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your username!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button
          className="btn-primary"
          onClick={handleSubmit}
          loading={loginLoading}
          disabled={loginLoading}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}
