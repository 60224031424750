import { Search } from 'lucide-react';

// Components
import Link from '../components/link/Link';
import ColumnSearch from '../pages/datafile/components/ColumnSearch';

// Utils
import UrlUtils from './urlUtils';

// Constants
import PageUrls from '../constants/pageUrls';
import { COLUMN_CONFIG } from '../constants/datasetConstants';

const getDatafileColumns = (onClickRedirect) => [
  {
    title: 'S.No',
    dataIndex: 'sno',
  },
  { title: 'File Type', dataIndex: 'fileType' },
  {
    title: 'Name',
    dataIndex: 'fileName',
  },
  {
    title: 'Action',
    key: 'action',
    render: (record) => onClickRedirect(record),
  },
];

/**
 *
 * @param {*} columnIndex ==> This columnIndex is used to identify which cell
 * in the table belongs to which column. In out case we are using title and columnIndex
 * to be same as the header.
 * @param {*} defaultSortOrder
 * @returns
 */
function getSortingConfig(columnIndex, defaultSortOrder = 'descend') {
  return {
    defaultSortOrder,
    sorter: (a, b) => a[columnIndex] - b[columnIndex],
  };
}

function getSearchConfig(datafileId, dataIndex) {
  return {
    filterDropdown: (props) => (
      <ColumnSearch
        datafileId={datafileId}
        searchProps={props}
        dataIndex={dataIndex}
      />
    ),

    filterIcon: (filtered) => {
      // TODO: How to get the color from the Layouts..
      const color = filtered ? '#1677ff' : undefined;
      return <Search size={12} color={color} />;
    },
  };
}

/**
 *  Function converts the dataFile content in the format reeded for Ant-D Table
 * Ant-D Table ==> https://ant.design/components/table
 *
 * @param {*} datafileId
 * @param {*} headers
 * @param {*} headersConfig ==> Config like is column sortable, searchable, etc..
 * @param {*} data
 * @param {*} tableConfig ==> Configuration of Table Column
 * @returns
 */
function constructTableData(
  datafileId,
  headers,
  headersConfig,
  data,
  tableConfig,
) {
  const { columnWidth } = tableConfig;

  // Construct columns
  const columns = [];
  for (const header of headers) {
    let column = {
      title: header,
      width: columnWidth,
      dataIndex: header,
      key: header,
    };

    // If the column is sortable then add the sorting config
    const isColumnSortable = headersConfig[header]?.[COLUMN_CONFIG.IS_SORTABLE];
    if (isColumnSortable) {
      column = { ...column, ...getSortingConfig(header) };
    }

    // If the column is searchable then add the searchable config
    const isSearchEnabled =
      headersConfig[header]?.[COLUMN_CONFIG.IS_SEARCHABLE];
    if (isSearchEnabled) {
      column = { ...column, ...getSearchConfig(datafileId, header) };
    }

    columns.push(column);
  }

  // Construct rows
  const rows = [];
  for (const dataPoint of data) {
    const row = {};
    for (let i = 0; i < dataPoint.length; i++) {
      const value = dataPoint[i];
      row['key'] = `${value}-${i}`;
      row[headers[i]] = value;
    }
    rows.push(row);
  }

  return { columns, rows };
}

function constructDatasetTableData(datasets = []) {
  // Construct rows
  const datasetRows = [];
  for (let i = 0; i < datasets.length; i++) {
    const { id, name = '', description = '' } = datasets[i];
    datasetRows.push({
      key: id,
      name,
      description,
      sno: i + 1,
    });
  }

  return datasetRows;
}

function constructDatafileTableData(datasetId, datafiles, tableConfig) {
  // Custom render function of Action column of the Table to redirect to the
  // respective Datafile Page.
  function onClickRedirect(record) {
    const { key: datafileId } = record;
    const redirectPath = UrlUtils.format(PageUrls.DatafileViewPage, {
      datasetId,
      datafileId,
    });
    return <Link className="ps-0" label="view" linkTo={redirectPath} />;
  }

  // construct columns
  const datafileColumns = getDatafileColumns(onClickRedirect);
  const { columnWidth } = tableConfig;
  const columns = [];
  for (const column of datafileColumns) {
    const tempColumn = { ...column };
    tempColumn['width'] = columnWidth;
    columns.push(tempColumn);
  }

  // Construct rows
  const datafileRows = [];
  for (let i = 0; i < datafiles.length; i++) {
    const { id, fileName, fileType } = datafiles[i];
    datafileRows.push({
      key: id,
      fileName,
      fileType,
      sno: i + 1,
    });
  }

  return { columns: datafileColumns, rows: datafileRows };
}

const BOOLEAN_VALUES = {
  true: 'Yes',
  false: 'No',
};

function constructColumnsDefinitionTableData(columnsList = []) {
  // Construct rows
  const columnsDefRows = columnsList.map((columnData, index) => {
    const {
      id,
      name = '-',
      description = '-',
      order = '-',
      units = '-',
      isMandatory = false,
      isSearchable = false,
      isSortable = false,
      isDerived = false,
      valueType = '-',
      format = '-',
      minBound = 0,
      maxBound = 0,
      allowedValueCSV = '-',
      remarks = '-',
    } = columnData;
    return {
      key: id,
      name,
      description,
      order,
      units,
      isMandatory: BOOLEAN_VALUES[isMandatory],
      isSearchable: BOOLEAN_VALUES[isSearchable],
      isSortable: BOOLEAN_VALUES[isSortable],
      isDerived: BOOLEAN_VALUES[isDerived],
      valueType,
      format,
      minBound,
      maxBound,
      allowedValueCSV,
      remarks,
      sno: index + 1,
    };
  });

  return columnsDefRows;
}

const DataTableUtils = {
  constructTableData,
  constructDatafileTableData,
  constructDatasetTableData,
  constructColumnsDefinitionTableData,
};

export default DataTableUtils;
