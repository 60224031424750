import { Upload } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import EmptyState from '../../components/empty-state/EmptyState';
import SkeletonTable from '../../components/antd/table/SkeletonTable';
import DataTable from '../../components/antd/table/DataTable';
import Button from '../../components/button/Button';

// Actions
import { DatafileActions } from '../../store/slices/datafile/datafileSlice';

// Icons
import xlsx from '../../assets/images/icons/excel.png';

// Constants
import { DefaultPagination } from '../../constants/webConstants';

// Page Components
import DatafileUploadModal from './components/DatafileUploadModal';
import { DatasetActions } from '../../store/slices/dataset/datasetSlice';

function Header({ datasetName, datafilesLoading, setOpenModal }) {
  return (
    <div className="d-flex justify-content-between my-4">
      <h2> Dataset : {datasetName}</h2>
      <Button
        className="btn-primary px-2"
        icon={<Upload size={14} />}
        loading={datafilesLoading}
        disabled={datafilesLoading}
        onClick={() => setOpenModal(true)}
      >
        New upload
      </Button>
    </div>
  );
}

/**
 * Dataset View Page
 */
export default function DatasetViewPage() {
  const { datasetId } = useParams();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  // Datafiles state
  const { columns = [], rows = [] } =
    useSelector((state) => state.datafile.datafiles) || {};
  const datafilesLoading = useSelector(
    (state) => state.datafile.listDatafilesLoading,
  );

  // Dataset State
  const { name } = useSelector((state) => state.dataset.dataset);

  useEffect(() => {
    // reset the datafiles
    dispatch(DatafileActions.reset());

    // Fetch the datafiles
    const pageNumber = DefaultPagination.pageNumber;
    const pageSize = DefaultPagination.maxPageSize;
    dispatch(
      DatafileActions.listDatafiles({ datasetId, pageNumber, pageSize }),
    );

    // Fetch the dataset Info
    dispatch(DatasetActions.getDataset({ datasetId }));

    // Fetch the dataset columns info
    dispatch(DatasetActions.getDatasetColumns({ datasetId }));
  }, [dispatch, datasetId]);

  return (
    <div className="page-content">
      {/** No Datasets section */}
      {!datafilesLoading && rows.length === 0 && (
        <div className="d-flex justify-content-center mt-5">
          <EmptyState
            title="No Datafiles"
            subTitle="upload data files to perform analysis "
            iconSource={xlsx}
            btnIcon={<Upload size={14} />}
            btnLabel="Upload datafile"
            btnLoading={datafilesLoading}
            btnSubmit={() => setOpenModal(true)}
          />
        </div>
      )}

      {/** Datasets Loading section */}
      {datafilesLoading && <SkeletonTable columnCount={5} rowCount={12} />}

      {!datafilesLoading && rows.length !== 0 && (
        <>
          <Header
            datasetName={name}
            datafilesLoading={datafilesLoading}
            setOpenModal={setOpenModal}
          />
          <DataTable
            rows={rows}
            columns={columns}
            applyOnlyTableBorder={true}
          />
        </>
      )}

      {/** Dataset Create Modal.. */}
      <DatafileUploadModal openModal={openModal} setOpenModal={setOpenModal} />
    </div>
  );
}
