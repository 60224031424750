import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Constants
import { DATASET_COLUMNS_DEFINITIONS_HEADER } from '../../../constants/datasetConstants';

// Components
import Button from '../../../components/button/Button';
import DataTable from '../../../components/antd/table/DataTable';

// To convert Yes or No to Boolean values
const BOOLEAN = {
  Yes: true,
  No: false,
};

// Page Components
function DatasetDetailsHeader({ name = '', setOpenModal = () => {} }) {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <h2 className={`${name === '' ? 'invisible' : 'visible'}`}>
        Dataset : {name}
      </h2>
      <Button onClick={() => setOpenModal(true)} className="btn-primary">
        Add Column
      </Button>
    </div>
  );
}

function ColumnActions({
  record = {},
  setOpenModal = () => {},
  setColumnData = () => {},
}) {
  const { isMandatory, isSearchable, isSortable, isDerived } = record;

  function setColumnEditData() {
    setOpenModal(true);
    setColumnData({
      ...record,
      isMandatory: BOOLEAN[isMandatory],
      isSearchable: BOOLEAN[isSearchable],
      isSortable: BOOLEAN[isSortable],
      isDerived: BOOLEAN[isDerived],
    });
  }
  return (
    <p
      className="text-primary p-0 m-0 cursor-pointer"
      onClick={setColumnEditData}
    >
      Edit
    </p>
  );
}

/**
 * Dataset Column Definition
 * @param {*} name : String
 * @param {*} setOpenModal : Function
 * @param {*} setEditColumnData : Function
 * @returns
 */
export default function DatasetColumnDefinitions({
  name = '',
  setOpenModal = () => {},
  setColumnData = () => {},
}) {
  // States
  const [columnsDefColumns, setColumnsDefColumns] = useState([]);

  // Selectors
  const columnsDefRows = useSelector((state) => state.dataset.columns);

  // Constructing the columns for column definitions
  const actionColumn = {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    render: (record) => (
      <ColumnActions
        record={record}
        setOpenModal={setOpenModal}
        setColumnData={setColumnData}
      />
    ),
  };

  useEffect(() => {
    setColumnsDefColumns([...DATASET_COLUMNS_DEFINITIONS_HEADER, actionColumn]);
  }, []);

  return (
    <>
      <DatasetDetailsHeader name={name} setOpenModal={setOpenModal} />
      <DataTable
        className="mt-3"
        rows={columnsDefRows}
        columns={columnsDefColumns}
        applyOnlyTableBorder={true}
      />
    </>
  );
}
