import { useState } from 'react';

// Components
import Layout from '../../components/layouts/Layout';
import TopnavMenu from '../../components/layouts/TopnavMenu';
import DatafileSidebarMenu from './DatafileSidebarMenu';

// Layout
import LayoutConfig from '../LayoutConfig';

/**
 * Main Page Layout
 */
export default function DatafileLayout() {
  // State
  const [pageHeader, setPageHeader] = useState(<TopnavMenu />);

  // Top bar Config
  // default will be added here for all same RoutePages
  // custom header can be added from page itself using hook : useOutletContext
  const topbarConfig = LayoutConfig.getTobBarConfig(pageHeader);
  const leftSidebarConfig = LayoutConfig.getLeftSidebarConfig();

  // Left Sidebar Config
  const rightSidebarConfig = {
    menuComponent: <DatafileSidebarMenu />,
  };

  return (
    <Layout
      topbarConfig={topbarConfig}
      leftSidebarConfig={leftSidebarConfig}
      rightSidebarConfig={rightSidebarConfig}
      outletProps={{ setPageHeader }}
    />
  );
}
