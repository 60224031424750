import { createBrowserRouter } from 'react-router-dom';

// Constants
import PageUrls from '../constants/pageUrls';

// Pages
import LoginPage from '../pages/auth/LoginPage';

// Routes
import { MainPageRoutes } from './MainRoutes';
import { DatafileRoutes } from './datafile/DatafileRoutes';

const Router = createBrowserRouter([
  MainPageRoutes,
  DatafileRoutes,

  {
    path: PageUrls.LoginPage,
    element: <LoginPage />,
  },
]);

export default Router;
