import { useEffect, useState } from 'react';
import useSelectedMenu from '../../hooks/useSelectMenu';

function getMenuClassName(isSidebarOpen, selectedMenu, id) {
  if (isSidebarOpen) {
    return `nav-items fw-semibold ${selectedMenu === id ? 'selected' : ''}`;
  }

  return `m-3 p-3 rounded-5 d-flex justify-content-center collapsed-items ${selectedMenu === id ? 'selected' : ''}`;
}

function MenuItem({
  menu = {},
  isSidebarOpen = false,
  selectedMenu = '',
  onSelect = () => {},
}) {
  const { id = '', title = '', icon = <></> } = menu;

  const [className, setClassName] = useState('');

  useEffect(() => {
    const menuClassName = getMenuClassName(isSidebarOpen, selectedMenu, id);
    setClassName(menuClassName);
  }, [isSidebarOpen, selectedMenu]);

  return (
    <li key={id} className={className} onClick={() => onSelect(id)}>
      {isSidebarOpen ? (
        <>
          <span className="me-3">{icon}</span>
          {title}
        </>
      ) : (
        <span>{icon}</span>
      )}
    </li>
  );
}

export default function SidebarMenu({
  isSidebarOpen = false,
  sidebarMenuList = [],
  sidebarMenuNavigationConfig = {},
}) {
  const { selectedKey, handleMenuClick } = useSelectedMenu(
    sidebarMenuNavigationConfig,
  );

  return (
    <ul className={`${isSidebarOpen ? 'nav-links' : 'collapsed-links'}`}>
      {sidebarMenuList.map((menu = {}) => {
        const { id = '' } = menu;
        return (
          <MenuItem
            key={id}
            menu={menu}
            isSidebarOpen={isSidebarOpen}
            selectedMenu={selectedKey}
            onSelect={handleMenuClick}
          />
        );
      })}
    </ul>
  );
}
