import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Alert, Form, Modal } from 'antd';

// Constants
import { DATASET_COLUMN_FORM_INPUT } from '../../../constants/datasetConstants';

// Actions
import { DatasetActions } from '../../../store/slices/dataset/datasetSlice';

// Components
import Button from '../../../components/button/Button';

const formInitalValues = {
  name: '',
  description: '',
  order: -1,
  units: '',
  isMandatory: false,
  isSearchable: false,
  isSortable: false,
  isDerived: false,
  valueType: '',
  format: '',
  minBound: 0,
  maxBound: 0,
  allowedValuesCSV: '',
  remarks: '',
};

// Modal Footer Function
function ModalFooter({
  btnLoading = false,
  handleReset = () => {},
  handleSubmit = () => {},
}) {
  return (
    <div className="d-flex justify-content-end gap-3">
      <Button
        onClick={handleReset}
        className="btn-outline-dark btn-outline-custom px-3 py-2"
      >
        Reset
      </Button>
      <Button
        onClick={handleSubmit}
        className="btn btn-primary"
        loading={btnLoading}
      >
        Submit
      </Button>
    </div>
  );
}

// Form Item Function
function FormItem({ data = {} }) {
  const { label, name, className, input, valuePropName, rules, help } = data;
  return (
    <Form.Item
      label={label}
      name={name}
      className={className}
      valuePropName={valuePropName}
      rules={rules}
      help={help}
    >
      {input}
    </Form.Item>
  );
}

/**
 * Dataset Add / Edit Column Modal
 * @param {*} openModal : Boolean
 * @param {*} setOpenModal : Function
 * @param {*} columnData : Boolean
 * @param {*} setColumnData : Function
 * @param {*} datasetId : integer
 *
 * @returns
 */
export default function DatasetAddEditColumnModal({
  openModal = false,
  setOpenModal = () => {},
  columnData = {},
  setColumnData = () => {},
  datasetId = '',
}) {
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  // States
  const [validationError, setValidationError] = useState('');
  const [showError, setShowError] = useState(false);

  const { key: columnId } = columnData;
  const formInput = DATASET_COLUMN_FORM_INPUT;
  const modalTitle = columnId
    ? 'Edit Column Definition'
    : 'Create New Column Definition';

  // Selectors
  const upsertColumnSuccess = useSelector(
    (state) => state.dataset.upsertColumnSuccess,
  );
  const upsertColumnLoading = useSelector(
    (state) => state.dataset.upsertColumnLoading,
  );
  const errorFromApi = useSelector((state) => state.dataset.upsertColumnError);

  // Handle Reset Function
  function handleReset() {
    form.resetFields();
    setColumnData({});
    setValidationError('');
    setShowError(false);
  }

  // Function to dispatch the update column
  function dispatchUpdateColumns(values) {
    dispatch(
      DatasetActions.updateColumn({
        datasetId,
        columnId,
        columnObj: { ...values, datasetId: parseInt(datasetId) },
      }),
    );
  }

  // Function to handle either create or update
  function handleUpsert(values = {}) {
    setValidationError('');
    setShowError(false);
    if (columnId) {
      dispatchUpdateColumns(values);
      return;
    }
    dispatch(DatasetActions.createColumn({ datasetId, columnObj: values }));
  }

  // Handle Submit Function
  function handleSubmit() {
    // Check and dispatch actions
    form
      .validateFields()
      .then((values) => {
        handleUpsert(values);
      })
      .catch((info) => {
        setValidationError(info.errorFields[0].errors[0]);
        setShowError(true);
      });
  }

  useEffect(() => {
    form.setFieldsValue(columnData);
  }, [columnData]);

  useEffect(() => {
    if (upsertColumnSuccess) {
      setOpenModal(false);
      setColumnData({});
    }

    if (Object.keys(errorFromApi).length !== 0) {
      setValidationError('Error while creating / updating column definition');
      setShowError(true);
    }
  }, [upsertColumnSuccess]);

  return (
    <Modal
      forceRender
      open={openModal}
      title={modalTitle}
      onCancel={() => setOpenModal(false)}
      afterClose={handleReset}
      width={700}
      footer={() => (
        <ModalFooter
          handleReset={handleReset}
          handleSubmit={handleSubmit}
          btnLoading={upsertColumnLoading}
        />
      )}
    >
      {/* Error Alert */}
      {showError && <Alert message={validationError} type="error" />}
      <Form
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        className="my-4"
        form={form}
        initialValues={formInitalValues}
      >
        {formInput.map((data) => {
          const { id = '' } = data;
          return <FormItem key={id} data={data} />;
        })}
      </Form>
    </Modal>
  );
}
