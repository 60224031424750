import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Constants
import { DefaultPagination } from '../../constants/webConstants';

// Components
import EmptyState from '../../components/empty-state/EmptyState';
import SkeletonTable from '../../components/antd/table/SkeletonTable';

// Actions
import { DatasetActions } from '../../store/slices/dataset/datasetSlice';

// Icons
import dataset from '../../assets/images/icons/dataset.png';

// Page Components
import DatasetTable from './components/DatasetTable';
import DatasetCreateEditModal from './components/DatasetCreateEditModal';

/**
 * Dataset List Page
 * @returns
 */

export default function DatasetListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // State to manage open modal and to handle edit dataset data
  const [openModal, setOpenModal] = useState(false);
  const [editDatasetData, setEditDatasetData] = useState({});

  // Selector
  const datasets = useSelector((state) => state.dataset.datasets);

  const datasetsLoading = useSelector(
    (state) => state.dataset.listDatasetLoading,
  );

  const isDataPresent = !datasetsLoading && datasets.length === 0;

  useEffect(() => {
    // reset the datasets
    dispatch(DatasetActions.reset());

    // Fetch the datasets...
    // TODO: remove hardcoaded pageSize when we need to implement pagination..
    const pageNumber = DefaultPagination.pageNumber;
    const pageSize = DefaultPagination.maxPageSize;
    dispatch(DatasetActions.listDatasets({ pageNumber, pageSize }));
  }, [dispatch]);

  return (
    <div className="page-content">
      {/** No Datasets section */}
      {isDataPresent && (
        <div className="d-flex justify-content-center mt-5">
          <EmptyState
            title="No Datasets Found"
            subTitle="Add datasets to start your analysis and build interactive visualizations"
            iconSource={dataset}
            btnLabel="Create dataset"
            btnLoading={datasetsLoading}
            btnSubmit={() => setOpenModal(true)}
          />
        </div>
      )}

      {/** Datasets Loading section */}
      {datasetsLoading && <SkeletonTable columnCount={5} rowCount={12} />}

      {/* Dataset Table with create datset button*/}
      {!isDataPresent && (
        <DatasetTable
          setOpenModal={setOpenModal}
          setEditDatasetData={setEditDatasetData}
        />
      )}

      {/** Dataset Create / Edit Modal.. */}
      <DatasetCreateEditModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        editDatasetData={editDatasetData}
        setEditDatasetData={setEditDatasetData}
      />
    </div>
  );
}
