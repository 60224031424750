// Pagination
export const DefaultPagination = {
  pageNumber: 1,
  pageSize: 20,
  totalCount: 0,
  maxPageSize: 10000,
};

// QueryParamsKeys
export const QueryParamsKeys = {
  pageNumber: 'pn',
  pageSize: 'ps',
  orgType: 'orgType',
  type: 'type',
  searchText: 'search',
};
