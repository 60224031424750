// Components
import Button from '../button/Button';

// Css
import './EmptyState.scss';

export default function EmptyState({
  title,
  subTitle,
  iconSource,
  btnIcon,
  btnLabel,
  btnLoading,
  btnSubmit = () => {},
}) {
  return (
    <div className="d-flex flex-column align-items-center">
      <img className="icon-source me-1" src={iconSource} alt="No Dataset" />
      <h3 className="fw-semibold mt-3">{title} </h3>
      <p>{subTitle}</p>
      <Button
        className="btn-primary px-2"
        icon={btnIcon}
        loading={btnLoading}
        disabled={btnLoading}
        onClick={btnSubmit}
      >
        {btnLabel}
      </Button>
    </div>
  );
}
