import { useEffect, useState } from 'react';
import { ArrowLeft } from 'lucide-react';

// Constants
import { DATA_FILE_SIDE_BAR_MENU } from '../../constants/layoutConstants';

// Hooks
import useUpdateQueryParams from '../../hooks/useUpdateQueryParams';

// Components
import Button from '../../components/button/Button';

// Icon Tab Function
function IconTab({
  menu = {},
  updateQueryParams,
  selectedIcon = '',
  setSelectedIcon = () => {},
}) {
  const { id = '', title = '', icon = <></> } = menu;

  function handleOnClick() {
    setSelectedIcon(id);

    const params = {
      graphType: title,
    };

    updateQueryParams({ params });
  }

  return (
    <div className="col d-flex flex-column justify-content-center align-items-center">
      <Button
        className={`btn p-3 border-1 rounded-circle ${selectedIcon ? 'btn-primary' : 'btn-light'}`}
        icon={icon}
        onClick={handleOnClick}
      ></Button>
      <p className={`fw-semibold mt-2 ${selectedIcon ? 'text-primary' : ''}`}>
        {title}
      </p>
    </div>
  );
}

/**
 * Data File Sidebar Menu
 */
export default function DatafileSidebarMenu({ setIsSidebarOpen = () => {} }) {
  const [selectedIcon, setSelectedIcon] = useState('');

  // eslint-disable-next-line no-unused-vars
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  const sidebarMenuList = DATA_FILE_SIDE_BAR_MENU;

  useEffect(() => {
    setIsSidebarOpen(false);
  }, []);
  return (
    <>
      <ArrowLeft
        className="ms-4 mt-4 cursor-pointer"
        onClick={() => setIsSidebarOpen(false)}
      />
      <div className="row m-0 py-4">
        {sidebarMenuList.map((menu = {}) => {
          const { id = '' } = menu;
          return (
            <IconTab
              key={id}
              menu={menu}
              updateQueryParams={updateQueryParams}
              selectedIcon={selectedIcon === id}
              setSelectedIcon={setSelectedIcon}
            />
          );
        })}
      </div>
    </>
  );
}
